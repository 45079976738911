import { Check, ChevronsUpDown, Menu, Pencil, PencilLine, Trash2, X } from 'lucide-react';
import React, { useContext, useEffect, useRef, useState } from 'react'
import HeadlessUIModalComponent from '../../shared/HeadlessUIModal';
import { NEWS_QUILL_TOOLBAR } from '../../../services/commonDataService';
import { BlogApiService, CategoryApiService, SubCategoryApiService } from '../../../services/apiService'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-hot-toast';
import UserContext from '../../../contexts/UserContext';
import { Switch } from '@headlessui/react';
import { Reorder } from 'framer-motion'
import { useNavigate, Link } from "react-router-dom";
const Content = () => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);

    const searchBoxRef = useRef(null);
    function handleClick() {
        navigate("/add-content");
    }
  
    const [showDeleteBlog, setShowDeleteBlog] = useState(false);


    const [allBlogs, setAllBlogs] = useState([]);
    const [blogList, setBlogList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);

    // Write blog states



    // Delete Blog states
    const [blogToDelete, setBlogToDelete] = useState(null);

    // Reordering states
    const [reordering, setReordering] = useState(false);
    const [initialOrder, setInitialOrder] = useState([]);

    const getCategories = () => {
        CategoryApiService.getCategories()
            .then(response => {
                if (response.data.success === true) {
                    setCategories(response.data.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    const getSubCategories = () => {
        SubCategoryApiService.getSubCategories()
            .then(response => {
                if (response.data.success === true) {
                    setSubCategories(response.data.data);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    const getBlogs = () => {
        BlogApiService.getBlogs({ token: user?.token })
            .then(response => {
                if (response.data.success === true) {
                    setAllBlogs(response.data.blogs);
                    setBlogList(response.data.blogs);
                    setInitialOrder(response.data.blogs.map(blog => blog.id));
                }
            })
            .catch(error => {
                console.log(error);
            });
    }


    useEffect(() => {
        getBlogs();
        getCategories();
        getSubCategories();
    }, []);

    const handleBlogLiveChange = (e, blogId) => {
        BlogApiService.changeBlogStatus({ token: user?.token, id: blogId, live: e })
            .then(response => {
                if (response.data.success === true) {
                    toast.success('Blog status changed successfully!');
                    getBlogs();
                }
            })
            .catch(error => {
                console.log(error);
            });
    }



    const blogsListJSX = (
        <div className="mt-4 flex flex-col">
            <div className="-mx-4 -my-2 overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden border border-teal-50 md:rounded-lg">
                        <table className="min-w-full divide-y divide-teal-50">
                            <thead className="bg-teal-300">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-left text-sm font-normal text-gray-700"
                                    >
                                        Blog
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-12 py-3.5 text-left text-sm font-normal text-gray-700"
                                    >
                                        Author
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-12 py-3.5 text-left text-sm font-normal text-gray-700"
                                    >
                                        Category
                                    </th>

                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-center text-sm font-normal text-gray-700"
                                    >
                                        Actions
                                    </th>
                                </tr>
                            </thead>

                            <Reorder.Group
                                as='tbody'
                                className="divide-y divide-teal-50 bg-teal-300/50"
                                values={blogList}
                                onReorder={setBlogList}
                            >
                                {blogList.map((blog) => (
                                    <Reorder.Item
                                        key={blog.id}
                                        value={blog}
                                        as='tr'
                                        drag='y'
                                        dragListener={reordering}
                                    >
                                        <td className="whitespace-nowrap flex items-center gap-2 px-4 py-4 w-3/6">
                                            {
                                                <span>
                                                    <ChevronsUpDown
                                                        width={20}
                                                        className={`text-gray-500 cursor-pointer transition-all duration-300 ${reordering ? 'opacity-100 -ml-0' : 'opacity-0 -ml-5'}`}
                                                    />
                                                </span>
                                            }
                                            <div>
                                                <div className="text-sm font-medium text-gray-900" title={blog.title}>{blog.title.substring(0, 50) + (blog.title.length > 50 ? '...' : '')}</div>
                                                <div className="text-sm text-gray-600" title={blog.description}>{blog.description.substring(0, 55) + (blog.description.length > 55 ? '...' : '')}</div>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap text-left px-12 py-4 w-1/6">
                                            <div className="text-sm text-gray-900">{blog.author}</div>
                                        </td>
                                        <td className="whitespace-nowrap text-left px-12 py-4 w-1/6">
                                            <div className="text-sm text-gray-900">{subCategories.find(cat => cat.id == blog.subcategory)?.name}</div>
                                            <div className="text-sm text-gray-700">{categories.find(cat => cat.id == blog.category)?.name}</div>
                                        </td>

                                        <td className="whitespace-nowrap  flex items-center gap-2 justify-center text-center px-4 py-2">
                                            <div>
                                                <Switch
                                                    checked={blog.live}
                                                    onChange={(e) => { handleBlogLiveChange(e, blog.id) }}
                                                    className={`bg-gray-400 text-white relative inline-flex h-[30px] w-[64px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        className={`${blog.live ? 'translate-x-[2.125rem] bg-green-600' : 'translate-x-0 bg-red-600'}
                                                            pointer-events-none inline-block h-[26px] w-[26px] transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
                                                    >
                                                        {blog.live
                                                            ?
                                                            <Check className='w-4 absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2' />
                                                            :
                                                            <X className='w-4 absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2' />
                                                        }
                                                    </span>
                                                </Switch>
                                            </div>

                                            <button
                                                className="text-amber-900 bg-amber-200 hover:bg-amber-300 p-2 rounded-lg focus:outline-none"

                                            >
                                                <Link to={`/edit-content/${blog.id}`} >   <PencilLine size={18} /></Link>

                                            </button>

                                            <button
                                                className="text-red-900 bg-red-200 hover:bg-red-300 p-2 rounded-lg focus:outline-none"
                                                onClick={() => {
                                                    setBlogToDelete(blog.id);
                                                    setShowDeleteBlog(true);
                                                }}
                                            >
                                                <Trash2 size={18} />
                                            </button>

                                        </td>
                                    </Reorder.Item>
                                ))}
                            </Reorder.Group>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )




    const handleReorderingSaveChanges = async (e) => {
        e.preventDefault();
        const updatedBlogs = blogList.map((blog, index) => {
            return { ...blog, order: index + 1 }
        });
        const changedBlogs = updatedBlogs.filter((blog, index) => blog.id !== initialOrder[index]);

        try {
            const updateOrderPromises = changedBlogs.map(blog => {
                return BlogApiService.changeBlogOrder({ token: user?.token, id: blog.id, order: blog.order });
            });
            const responses = await Promise.all(updateOrderPromises);
            if (responses.every(response => response.data.success === true)) {
                toast.success('Blogs reordered successfully!');
                setReordering(false);
                getBlogs();
            }
        } catch (error) {
            console.error(error);
        }
    }


    return (
        <div className='p-5'>



            {/* Delete blog */}
            <HeadlessUIModalComponent
                displayState={showDeleteBlog}
                setDisplayState={setShowDeleteBlog}
                headingChildren='Delete Blog'
                bodyChildren='Are you sure you want to delete this blog?'
                footerChildren={
                    <div className='flex gap-2'>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                            onClick={() => {
                                BlogApiService.deleteBlog({ token: user?.token, id: blogToDelete })
                                    .then(response => {
                                        if (response.data.success === true) {
                                            toast.success('Blog deleted successfully!');
                                            getBlogs();
                                            setShowDeleteBlog(false);
                                        }
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });
                            }}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-red-200 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                            onClick={() => { setShowDeleteBlog(false) }}
                        >
                            Cancel
                        </button>
                    </div>
                }
                maxWidthClass='max-w-md'
            />


            <div className='flex items-center justify-between' >
                <h1 className='text-2xl'>Content</h1>
                <button
                    onClick={handleClick}
                    disabled={reordering}
                    className='bg-teal-200 disabled:opacity-50 flex items-center gap-2 font-medium text-[15px] py-2 px-4 rounded-lg hover:bg-teal-300 text-teal-900 focus:outline-none'
                >
                    <Pencil className='h-5 w-5' /> Write
                </button>

            </div>
            <div className='mt-5'>
                <div className="flex mx-5 justify-between">
                    <div className='flex items-center gap-2'>
                        {reordering
                            ?
                            <>
                                <button
                                    onClick={handleReorderingSaveChanges}
                                    className='bg-teal-600 flex items-center gap-1 text-teal-100 hover:bg-teal-700 px-3 py-1.5 font-medium rounded-md'
                                >
                                    <Check size={20} /> Save Changes
                                </button>
                                <button
                                    onClick={() => {
                                        setBlogList(allBlogs);
                                        setReordering(false)
                                    }}
                                    className='bg-teal-200 flex items-center gap-1 text-teal-900 hover:bg-teal-300 px-3 py-1.5 font-medium rounded-md'
                                >
                                    <X size={20} /> Cancel
                                </button>
                            </>
                            :
                            <button
                                onClick={() => {
                                    searchBoxRef.current.value = '';
                                    setBlogList(allBlogs);
                                    setReordering(true)
                                }}
                                className='bg-teal-600 flex items-center gap-1 text-teal-100 hover:bg-teal-700 px-3 py-1.5 font-medium rounded-md'
                            >
                                <Menu size={20} /> Reorder
                            </button>
                        }
                    </div>
                    <input
                        type='search'
                        placeholder='Search blogs...'
                        ref={searchBoxRef}
                        disabled={reordering}
                        onChange={(e) => {
                            let val = e.target.value.trim();
                            if (val === '') {
                                setBlogList(allBlogs);
                                return;
                            }
                            setBlogList(allBlogs.filter(blog => blog.title.toLowerCase().includes(val.toLowerCase())));
                        }}
                        className='border-2 border-gray-400 focus:outline-none focus:border-teal-600 px-2 w-72 rounded-lg'
                    />
                </div>

                {blogsListJSX}
            </div>
        </div>
    )
}

export default Content;
import { Plus, Trash2, Turtle } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react'
import HeadlessUIModalComponent from '../../shared/HeadlessUIModal';
import { CategoryApiService, SubCategoryApiService } from '../../../services/apiService'
import { toast } from 'react-hot-toast';
import UserContext from '../../../contexts/UserContext';

const Categories = () => {

    const { user } = useContext(UserContext);

    const [showAddCategory, setShowAddCategory] = useState(false);
    const [showDeleteCategory, setShowDeleteCategory] = useState(false);
    const [showAddSubCategory, setShowAddSubCategory] = useState(false);
    const [showDeleteSubCategory, setShowDeleteSubCategory] = useState(false);

    const [addCategoryName, setAddCategoryName] = useState("");
    const [categoryToDel, setCategoryToDel] = useState(null);

    const [addSubCategoryName, setAddSubCategoryName] = useState("");
    const [addSubCategoryParent, setAddSubCategoryParent] = useState("");
    const [subCategoryToDel, setSubCategoryToDel] = useState(null);

    const [categories, setCategories] = useState([]);


    const [subCategories, setSubCategories] = useState([]);



    const getCategories = () => {
        CategoryApiService.getCategories()
            .then(res => {
                if (res.data.success === true) {
                    setCategories(res.data.data)
                }
            }).catch(err => console.error(err))
    }

    const getSubCategories = () => {
        SubCategoryApiService.getSubCategories()
            .then(res => {
                if (res.data.success === true) {
                    setSubCategories(res.data.data)
                }
            }).catch(err => console.error(err))
    }

    useEffect(() => {
        getCategories();
        getSubCategories();
    }, [])

    const categoryListJSX = (
        <div className='rounded-3xl overflow-hidden'>
            <table className="w-[90%] mx-auto divide-y divide-teal-50">
                <thead className="bg-teal-300">
                    <tr>
                        <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-medium text-gray-700"
                        >
                            Category
                        </th>
                        <th
                            scope="col"
                            className="px-4 py-3.5 text-center text-sm font-medium text-gray-700"
                        >
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-teal-50 bg-teal-300/50">
                    {categories.map((category) => (
                        <tr key={category.id}>
                            <td className="whitespace-nowrap text-left px-3 py-4">
                                <div className="text-sm text-gray-800">{category.name}</div>
                            </td>
                            <td className="whitespace-nowrap px-4 py-4 text-center text-sm font-medium flex gap-1">
                                <button onClick={() => { setCategoryToDel(category.id); setShowDeleteCategory(true) }} title='Delete Category' className="rounded-xl bg-red-200 mx-auto p-2 text-sm font-semibold text-red-900 shadow-sm hover:bg-red-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
                                    <Trash2 size={20} />
                                </button>
                            </td>
                        </tr>
                    ))}
                    {categories.length === 0 && <tr>
                        <td className="whitespace-nowrap text-center px-3 py-4" colSpan={2}>
                            No Categories to display!
                        </td>
                    </tr>}
                </tbody>
            </table>
        </div>
    )

    const subCategoryListJSX = (
        <div className='rounded-3xl overflow-hidden'>
            <table className="w-[90%] mx-auto divide-y divide-teal-50">
                <thead className="bg-teal-300">
                    <tr>
                        <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-medium text-gray-700"
                        >
                            Sub category
                        </th>
                        <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-medium text-gray-700"
                        >
                            Parent category
                        </th>
                        <th
                            scope="col"
                            className="px-4 py-3.5 text-center text-sm font-medium text-gray-700"
                        >
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-teal-50 bg-teal-300/50">
                    {subCategories.map((subcategory) => (
                        <tr key={subcategory.id}>
                            <td className="whitespace-nowrap text-left px-3 py-4">
                                <div className="text-sm text-gray-800">{subcategory.name}</div>
                            </td>
                            <td className="whitespace-nowrap text-left px-3 py-4">
                                <div className="text-sm text-gray-800">{subcategory.parentCategoryName}</div>
                            </td>
                            <td className="whitespace-nowrap px-4 py-4 text-center text-sm font-medium flex gap-1">
                                <button onClick={() => { setSubCategoryToDel(subcategory.id); setShowDeleteSubCategory(true) }} title='Delete sub category' className="rounded-xl bg-red-200 mx-auto p-2 text-sm font-semibold text-red-900 shadow-sm hover:bg-red-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
                                    <Trash2 size={20} />
                                </button>
                            </td>
                        </tr>
                    ))}
                    {subCategories.length === 0 && <tr>
                        <td className="whitespace-nowrap text-center px-3 py-4" colSpan={3}>
                            No Sub Categories to display!
                        </td>
                    </tr>}
                </tbody>
            </table>
        </div>
    )

    const addCategoryModalBodyJSX = (
        <div className='flex flex-col gap-4 my-5'>
            <div className='flex flex-col gap-2'>
                <label htmlFor="name" className="text-sm font-medium text-gray-700">Category Name</label>
                <input
                    type="text"
                    name="name"
                    id="name"
                    required
                    value={addCategoryName}
                    onChange={(e) => setAddCategoryName(e.target.value)}
                    className="block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm"
                />
            </div>
        </div >
    )

    const addSubCategoryModalBodyJSX = (
        <div className='flex flex-col gap-4 my-5'>
            <div className='flex flex-col gap-2'>
                <label htmlFor="name" className="text-sm font-medium text-gray-700">Sub Category Name</label>
                <input
                    type="text"
                    name="name"
                    id="name"
                    required
                    value={addSubCategoryName}
                    onChange={(e) => setAddSubCategoryName(e.target.value)}
                    className="block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm"
                />
            </div>
            <div className='flex flex-col gap-2'>
                <label htmlFor="parent" className="text-sm font-medium text-gray-700">Parent Category</label>
                <select
                    id="parent"
                    required
                    value={addSubCategoryParent}
                    onChange={(e) => setAddSubCategoryParent(e.target.value)}
                    className="block w-full px-3 py-2 border-2 border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 focus:outline-none sm:text-sm"
                >
                    <option value="" disabled>Select parent category</option>
                    {categories.map(cat => (
                        <option key={cat.id} value={cat.id}>{cat.name}</option>
                    ))}
                </select>
            </div>
        </div >
    )

    const handleAddCategory = () => {
        if (!addCategoryName) {
            toast.error("Please enter category name")
            return;
        }
        if (categories.find(cat => cat.name.toLowerCase() === addCategoryName.toLowerCase())) {
            toast.error("Category already exists")
            return;
        }

        CategoryApiService.addCategory({ token: user?.token, name: addCategoryName })
            .then(res => {
                if (res.data.success === true) {
                    toast.success("Category added successfully.")
                    setAddCategoryName("");
                    getCategories();
                    setShowAddCategory(false)
                } else {
                    toast.error(res.data.message)
                }
            }).catch(err => {
                console.error(err);
                toast.error(err.response.data.message)
            })
    }

    const handleAddSubCategory = () => {
        if (!addSubCategoryName || !addSubCategoryParent) {
            toast.error("Please enter category name and select parent category")
            return;
        }
        let existing = subCategories.find(cat => cat.name.toLowerCase() === addSubCategoryName.toLowerCase());
        if (existing && existing.parentCategoryId === parseInt(addSubCategoryParent)) {
            toast.error("Sub Category already exists")
            return;
        }

        const body = {
            token: user?.token,
            name: addSubCategoryName,
            parentCategoryId: addSubCategoryParent,
            parentCategoryName: categories.find(cat => cat.id === parseInt(addSubCategoryParent))?.name
        }

        SubCategoryApiService.addSubCategory(body)
            .then(res => {
                if (res.data.success === true) {
                    toast.success("Sub Category added successfully.")
                    setAddSubCategoryName("");
                    setAddSubCategoryParent("");
                    getSubCategories();
                    setShowAddSubCategory(false)
                } else {
                    toast.error(res.data.message)
                }
            }).catch(err => {
                console.error(err);
                toast.error(err.response.data.message)
            })
    }

    const handleDeleteCategory = () => {
        if (categoryToDel === null) {
            toast.error("No category to delete!")
            return;
        }

        CategoryApiService.deleteCategory({ token: user?.token, id: categoryToDel })
            .then(res => {
                if (res.data.success === true) {
                    toast.success("Category deleted successfully.");
                    getCategories();
                    getSubCategories();
                    setShowDeleteCategory(false);
                    setCategoryToDel(null);
                }
            }).catch(err => {
                console.error(err);
                toast.error("Some error occurred!")
            })
    }

    const handleDeleteSubCategory = () => {
        if (subCategoryToDel === null) {
            toast.error("No sub category to delete!")
            return;
        }

        SubCategoryApiService.deleteSubCategory({ token: user?.token, id: subCategoryToDel })
            .then(res => {
                if (res.data.success === true) {
                    toast.success("Sub category deleted successfully.");
                    getSubCategories();
                    setShowDeleteSubCategory(false);
                    setSubCategoryToDel(null);
                }
            }).catch(err => {
                console.error(err);
                toast.error("Some error occurred!")
            })
    }

    return (
        <div className='p-5 h-screen'>
            {/* Add Category modal */}
            <HeadlessUIModalComponent
                displayState={showAddCategory}
                setDisplayState={setShowAddCategory}
                headingChildren={"Add new category"}
                bodyChildren={addCategoryModalBodyJSX}
                footerChildren={
                    <div className='flex items-center gap-2'>
                        <div className='flex gap-2'>
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-teal-100 hover:bg-teal-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                                onClick={handleAddCategory}
                            >
                                Add Category
                            </button>
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-teal-200 px-4 py-2 text-sm font-medium text-teal-900 hover:bg-teal-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                                onClick={() => { setShowAddCategory(false) }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                }
            />

            {/* Delete category modal */}
            <HeadlessUIModalComponent
                displayState={showDeleteCategory}
                setDisplayState={setShowDeleteCategory}
                headingChildren={"Delete category"}
                bodyChildren={
                    <div className='my-5'>
                        <p className='my-5'>Do you really want to delete <b>{categories.find(cat => cat.id === categoryToDel)?.name}?</b></p>
                        <p className='text-red-500 font-medium'>All subcategories under this category will also be deleted</p>
                    </div>
                }
                footerChildren={
                    <div className='flex items-center gap-2'>
                        <div className='flex gap-2'>
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-red-100 hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                                onClick={handleDeleteCategory}
                            >
                                Delete Category
                            </button>
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-red-200 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                                onClick={() => { setShowDeleteCategory(false); setCategoryToDel(null) }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                }
            />


            {/* Add SubCategory modal */}
            <HeadlessUIModalComponent
                displayState={showAddSubCategory}
                setDisplayState={setShowAddSubCategory}
                headingChildren={"Add new sub category"}
                bodyChildren={addSubCategoryModalBodyJSX}
                footerChildren={
                    <div className='flex items-center gap-2'>
                        <div className='flex gap-2'>
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-teal-100 hover:bg-teal-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                                onClick={handleAddSubCategory}
                            >
                                Add Sub Category
                            </button>
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-teal-200 px-4 py-2 text-sm font-medium text-teal-900 hover:bg-teal-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                                onClick={() => { setShowAddSubCategory(false) }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                }
            />

            {/* Delete subcategory modal */}
            <HeadlessUIModalComponent
                displayState={showDeleteSubCategory}
                setDisplayState={setShowDeleteSubCategory}
                headingChildren={"Delete Sub category"}
                bodyChildren={
                    <p className='my-5'>Do you really want to delete <b>{subCategories.find(cat => cat.id === subCategoryToDel)?.name}?</b></p>
                }
                footerChildren={
                    <div className='flex items-center gap-2'>
                        <div className='flex gap-2'>
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-red-100 hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                                onClick={handleDeleteSubCategory}
                            >
                                Delete Sub Category
                            </button>
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-red-200 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                                onClick={() => { setShowDeleteSubCategory(false); setSubCategoryToDel(null) }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                }
            />


            <h1 className='text-2xl'>Categories & Sub-categories</h1>
            <div className="flex my-5 gap-2 h-[calc(100vh-20%)]">
                {/* Categories */}
                <div className='w-2/5 bg-teal-50 border border-teal-200 p-3 rounded-md'>
                    <div className="flex items-center">
                        <h1 className='text-xl'>Categories</h1>
                        <button
                            onClick={() => setShowAddCategory(true)}
                            className='ml-auto bg-teal-200 text-teal-900 hover:bg-teal-300 p-2 rounded-md'
                        >
                            <Plus size={24} />
                        </button>
                    </div>

                    <div className='h-[85%] my-5 overflow-y-auto'>
                        {categoryListJSX}
                    </div>
                </div>
                {/* SubCategories */}
                <div className='w-3/5 bg-teal-50 border border-teal-200 p-3 rounded-md'>
                    <div className="flex items-center">
                        <h1 className='text-xl'>Sub-categories</h1>
                        <button
                            onClick={() => setShowAddSubCategory(true)}
                            className='ml-auto bg-teal-200 text-teal-900 hover:bg-teal-300 p-2 rounded-md'
                        >
                            <Plus size={24} />
                        </button>
                    </div>

                    <div className='h-[85%] my-5 overflow-y-auto'>
                        {subCategoryListJSX}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Categories;
import React, { useContext, useEffect, useState } from 'react'
import { DemoBookingApiService } from '../../../services/apiService'
import UserContext from '../../../contexts/UserContext'
import { Eye, Trash2 } from 'lucide-react'
import HeadlessUIModalComponent from '../../shared/HeadlessUIModal'
import { toast } from 'react-hot-toast'

const DemoBookings = () => {

    const { user } = useContext(UserContext)

    const [demoBookings, setDemoBookings] = useState([])
    const [currentBookings, setCurrentBookings] = useState([])

    const [showViewBooking, setShowViewBooking] = useState(false)
    const [showDeleteBooking, setShowDeleteBooking] = useState(false)

    const [selectedViewBooking, setSelectedViewBooking] = useState({})
    const [selectedDeleteBooking, setSelectedDeleteBooking] = useState(null)

    const getBookingsList = () => {
        DemoBookingApiService.getDemoBookings({ token: user.token })
            .then(res => {
                if (res.data.success) {
                    setDemoBookings(res.data.data)
                    setCurrentBookings(res.data.data)
                }
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getBookingsList()
    }, [])

    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    const demoBookingsListJSX = (
        <div className="mt-6 flex flex-col">
            <div className="-mx-4 -my-2">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden border border-teal-50 md:rounded-lg">
                        <table className="min-w-full divide-y divide-teal-50">
                            <thead className="bg-teal-300">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-left text-sm font-normal text-gray-700"
                                    >
                                        Booked by
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-12 py-3.5 text-left text-sm font-normal text-gray-700"
                                    >
                                        Contact
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-12 py-3.5 text-left text-sm font-normal text-gray-700"
                                    >
                                        Company
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-12 py-3.5 text-left text-sm font-normal text-gray-700"
                                    >
                                        Date
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-center text-sm font-normal text-gray-700"
                                    >
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-teal-50 bg-teal-300/50">
                                {currentBookings.map((booking) => (
                                    <tr key={booking.id}>
                                        <td className="whitespace-nowrap px-4 py-4">
                                            <div className="text-sm text-gray-900">{booking.firstname} {booking.lastname}</div>
                                            <div className="text-sm text-gray-700">{booking.jobTitle}</div>
                                        </td>
                                        <td className="whitespace-nowrap text-left px-12 py-4">
                                            <div className="text-sm text-gray-900">{booking.email}</div>
                                            <div className="text-sm text-gray-700">{booking.phone}</div>
                                        </td>
                                        <td className="whitespace-nowrap text-left px-12 py-4">
                                            <div className="text-sm text-gray-900">{booking.company}</div>
                                            <div className="text-sm text-gray-700">{booking.country}</div>
                                        </td>
                                        <td className="whitespace-nowrap text-left px-12 py-4">
                                            <div className="text-sm text-gray-900">{getFormattedDate(booking.createdAt)}</div>
                                        </td>
                                        <td className="whitespace-nowrap text-center px-12 py-2">
                                            <div className="flex items-center gap-2">
                                                <button
                                                    className="text-amber-900 bg-amber-200 hover:bg-amber-300 p-2 rounded-lg focus:outline-none"
                                                    onClick={() => {
                                                        setSelectedViewBooking(booking);
                                                        setShowViewBooking(true);
                                                    }}
                                                >
                                                    <Eye size={18} />
                                                </button>
                                                <button
                                                    className="text-red-900 bg-red-200 hover:bg-red-300 p-2 rounded-lg focus:outline-none"
                                                    onClick={() => {
                                                        setSelectedDeleteBooking(booking.id);
                                                        setShowDeleteBooking(true);
                                                    }}
                                                >
                                                    <Trash2 size={18} />
                                                </button>
                                            </div>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

    const viewBookingModalBodyJSX = (
        <div className="my-5 flex flex-col gap-2">
            <div className="flex items-center gap-4">
                <p className="font-semibold">Name:</p>
                <p>{selectedViewBooking.firstname} {selectedViewBooking.lastname}</p>
            </div>
            <div className="flex items-center gap-4">
                <p className="font-semibold">Job Title:</p>
                <p>{selectedViewBooking.jobTitle}</p>
            </div>
            <div className="flex items-center gap-4">
                <p className="font-semibold">Email:</p>
                <p>{selectedViewBooking.email}</p>
            </div>
            <div className="flex items-center gap-4">
                <p className="font-semibold">Phone:</p>
                <p>{selectedViewBooking.phone}</p>
            </div>
            <div className="flex items-center gap-4">
                <p className="font-semibold">Company:</p>
                <p>{selectedViewBooking.company}</p>
            </div>
            <div className="flex items-center gap-4">
                <p className="font-semibold">Number of Employees:</p>
                <p>{selectedViewBooking.numberOfEmployees}</p>
            </div>
            <div className="flex items-center gap-4">
                <p className="font-semibold">Industry:</p>
                <p>{selectedViewBooking.industry}</p>
            </div>
            <div className="flex items-center gap-4">
                <p className="font-semibold">Country:</p>
                <p>{selectedViewBooking.country}</p>
            </div>
            <div className="flex items-center gap-4">
                <p className="font-semibold">Date:</p>
                <p>{getFormattedDate(selectedViewBooking.createdAt)}</p>
            </div>

        </div>
    );

    const handleDeleteDemoBooking = () => {
        let body = {
            token: user.token,
            id: parseInt(selectedDeleteBooking)
        }
        DemoBookingApiService.deleteDemoBooking(body)
            .then(res => {
                if (res.data.success === true) {
                    toast.success('Booking deleted successfully');
                    setShowDeleteBooking(false);
                    setSelectedDeleteBooking(null);
                    getBookingsList()
                }
            })
            .catch(err => {
                console.log(err)
                toast.error(err.response.data.message)
            })
    }

    return (
        <div className='p-5'>
            {/* View */}
            <HeadlessUIModalComponent
                displayState={showViewBooking}
                setDisplayState={setShowViewBooking}
                headingChildren={'Demo Booking Details'}
                bodyChildren={viewBookingModalBodyJSX}
                footerChildren={
                    <div className='flex justify-end'>
                        <button
                            className='px-4 py-2 font-medium bg-teal-200 text-teal-900 hover:bg-teal-300 rounded-lg focus:outline-none'
                            onClick={() => setShowViewBooking(false)}
                        >
                            Close
                        </button>
                    </div>
                }
            />

            {/* Delete */}
            <HeadlessUIModalComponent
                displayState={showDeleteBooking}
                setDisplayState={setShowDeleteBooking}
                headingChildren={'Delete Demo Booking'}
                bodyChildren={
                    <div className='flex my-5 items-center gap-4'>
                        <p>Are you sure you want to delete this booking by <b>{demoBookings.find(db => db.id === parseInt(selectedDeleteBooking))?.firstname} {demoBookings.find(db => db.id === parseInt(selectedDeleteBooking))?.lastname}</b>?</p>
                    </div>
                }
                footerChildren={
                    <div className='flex justify-end gap-2'>
                        <button
                            className='px-4 py-2 font-medium bg-red-600 text-red-100 hover:bg-red-700 rounded-lg focus:outline-none'
                            onClick={handleDeleteDemoBooking}
                        >
                            Delete
                        </button>
                        <button
                            className='px-4 py-2 font-medium bg-red-200 text-red-900 hover:bg-red-300 rounded-lg focus:outline-none'
                            onClick={() => {
                                setShowDeleteBooking(false);
                                setSelectedDeleteBooking(null);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                }
            />

            <div className='flex items-center justify-between' >
                <h1 className='text-2xl'>Demo Bookings</h1>
                <input
                    type='search'
                    placeholder='Search by name, email or company'
                    onChange={(e) => {
                        let searchValue = e.target.value.trim().toLowerCase();
                        if (searchValue === '') {
                            setCurrentBookings(demoBookings)
                        } else {
                            setCurrentBookings(demoBookings.filter(db => (
                                db.firstname.toLowerCase().includes(searchValue)
                                || db.lastname.toLowerCase().includes(searchValue)
                                || `${db.firstname} ${db.lastname}`.toLowerCase().includes(searchValue)
                                || db.email.toLowerCase().includes(searchValue)
                                || db.company.toLowerCase().includes(searchValue)
                            )))
                        }
                    }}
                    className='px-3 py-1.5 border-2 border-gray-300 focus:border-teal-600 focus:outline-none rounded-lg w-96'
                />
            </div>
            <div className='mt-5 mx-auto'>
                {demoBookingsListJSX}
            </div>

        </div>
    )
}

export default DemoBookings;
import React, { useState } from 'react'
import UserContext from './UserContext';
import { UserApiService } from '../services/apiService';
import { toast } from 'react-hot-toast';

const UserContextProvider = ({ children }) => {

    const [user, setUser] = useState(null);

    const getUserInfo = (token) => {
        UserApiService.getUserInfo({ token })
            .then(res => {
                if (res.data.success === true) {
                    setUser({ ...res.data.data, token })
                }
            }).catch(err => console.error(err))
    }

    const logoutUser = () => {
        localStorage.removeItem('BCAdminToken');
        setUser(null)
        toast.success("Logged out successfully.")
    }

    return (
        <UserContext.Provider value={{ user, getUserInfo, logoutUser }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContextProvider;
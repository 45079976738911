import React, { useContext, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { BriefcaseBusiness, CircleFadingPlus, Gauge, Grid2X2, Home, LogOut, NotebookText, Target, Users } from 'lucide-react'
import logo from '../../assets/logo-dark.svg'
import UserContext from '../../contexts/UserContext'

const Sidebar = () => {

    const location = useLocation();
    const { logoutUser, user } = useContext(UserContext);

    return (
        <aside className="flex h-screen w-56 flex-col items-center overflow-y-auto bg-teal-100 py-8 px-4">
            <nav className="flex flex-1 flex-col w-full items-center space-y-2">
                <img
                    src={logo}
                    className='mr-auto mb-5'
                />
                <Link
                    to="/"
                    title='Dashboard'
                    className={`rounded-lg flex w-full items-center gap-2 p-2 text-gray-900 transition-colors duration-200 focus:outline-none ${location.pathname == "/" ? 'bg-teal-700 text-white' : 'hover:bg-teal-200'}`}
                >
                    <Gauge size={20} /> Dashboard
                </Link>

                <Link
                    to="/categories"
                    title='Categories'
                    className={`rounded-lg flex w-full items-center gap-2 p-2 text-gray-900 transition-colors duration-200 focus:outline-none ${location.pathname == "/categories" ? 'bg-teal-700 text-white' : 'hover:bg-teal-200'}`}
                >
                    <Grid2X2 size={20} /> Categories
                </Link>
                <Link
                    to="/brand-logos"
                    title='Brand Logos'
                    className={`rounded-lg flex w-full items-center gap-2 p-2 text-gray-900 transition-colors duration-200 focus:outline-none ${location.pathname == "/brand-logos" ? 'bg-teal-700 text-white' : 'hover:bg-teal-200'}`}
                >
                    <Target size={20} /> Brand Logos
                </Link>
                <Link
                    to="/content"
                    title='Content'
                    className={`rounded-lg flex w-full items-center gap-2 p-2 text-gray-900 transition-colors duration-200 focus:outline-none ${location.pathname == "/content" ? 'bg-teal-700 text-white' : 'hover:bg-teal-200'}`}
                >
                    <CircleFadingPlus size={20} /> Content
                </Link>
                <Link
                    to="/glossaries"
                    title='Glossaries'
                    className={`rounded-lg flex w-full items-center gap-2 p-2 text-gray-900 transition-colors duration-200 focus:outline-none ${location.pathname == "/glossaries" ? 'bg-teal-700 text-white' : 'hover:bg-teal-200'}`}
                >
                    <NotebookText size={20} /> Glossaries
                </Link>
                {user?.roleId === 1 && <Link
                    to="/users"
                    title='Users'
                    className={`rounded-lg flex w-full items-center gap-2 p-2 text-gray-900 transition-colors duration-200 focus:outline-none ${location.pathname == "/users" ? 'bg-teal-700 text-white' : 'hover:bg-teal-200'}`}
                >
                    <Users size={20} /> Users
                </Link>}
                <Link
                    to="/demo-bookings"
                    title='Demo Bookings'
                    className={`rounded-lg flex w-full items-center gap-2 p-2 text-gray-900 transition-colors duration-200 focus:outline-none ${location.pathname == "/demo-bookings" ? 'bg-teal-700 text-white' : 'hover:bg-teal-200'}`}
                >
                    <BriefcaseBusiness size={20} /> Demo Bookings
                </Link>
            </nav>

            <div className="flex flex-col w-full items-center">
                <p className='text-left w-full p-2'> Hey, {user.name}</p>
                <button
                    onClick={logoutUser}
                    className={`rounded-lg flex w-full items-center gap-2 p-2 text-gray-900 transition-colors duration-200 focus:outline-none hover:bg-teal-200`}
                >
                    <LogOut size={20} /> Sign Out
                </button>
            </div>
        </aside>
    )
}

export default Sidebar;
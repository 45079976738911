import React, { useContext, useEffect, useState } from 'react'
import UserContext from '../../../contexts/UserContext'
import { BrandLogoApiService } from '../../../services/apiService';
import { Eye, ImageUp, Plus, Trash2 } from 'lucide-react'
import HeadlessUIModalComponent from "../../shared/HeadlessUIModal";
import { toast } from 'react-hot-toast';
import { LOGOS_BASE_URL } from '../../../services/commonDataService';

const BrandLogos = () => {

    const { user } = useContext(UserContext)

    const [showAddBrandLogo, setShowAddBrandLogo] = useState(false);
    const [showDisplayBrandLogo, setShowDisplayBrandLogo] = useState(false);
    const [showDeleteBrandLogo, setShowDeleteBrandLogo] = useState(false);

    const [addBrandLogoFile, setAddBrandLogoFile] = useState(null);
    const [displayBrandLogoId, setDisplayBrandLogoId] = useState(1);
    const [deleteBrandLogoId, setDeleteBrandLogoId] = useState(null);

    const [brandLogos, setBrandLogos] = useState([]);

    const getBrandLogos = () => {
        BrandLogoApiService.getBrandLogos()
            .then(res => {
                setBrandLogos(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getBrandLogos();
    }, []);

    const brandLogoListJSX = (
        <div className="mt-6 flex flex-col w-10/12 mx-auto">
            <div className="-mx-4 -my-2 overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden border border-teal-50 md:rounded-lg">
                        <table className="min-w-full divide-y divide-teal-50">
                            <thead className="bg-teal-300">
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-left text-sm font-normal text-gray-700"
                                    >
                                        Logo
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-4 py-3.5 text-center text-sm font-normal text-gray-700"
                                    >
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-teal-200 bg-[#1e7992]">
                                {brandLogos.map((logo) => (
                                    <tr key={logo.id}>
                                        <td className="whitespace-nowrap px-4 py-4">
                                            <img
                                                src={LOGOS_BASE_URL + logo.image}
                                                className='max-w-24'
                                            />
                                        </td>
                                        <td className="whitespace-nowrap my-auto flex items-center justify-center gap-2 text-center px-12 py-2">
                                            <button
                                                className="text-amber-900 bg-amber-200 hover:bg-amber-300 p-2 rounded-lg focus:outline-none"
                                                onClick={() => {
                                                    setDisplayBrandLogoId(logo.id);
                                                    setShowDisplayBrandLogo(true);
                                                }}
                                            >
                                                <Eye size={18} />
                                            </button>

                                            <button
                                                className="text-red-900 bg-red-200 hover:bg-red-300 p-2 rounded-lg focus:outline-none"
                                                onClick={() => {
                                                    setDeleteBrandLogoId(logo.id);
                                                    setShowDeleteBrandLogo(true);
                                                }}
                                            >
                                                <Trash2 size={18} />
                                            </button>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

    const addBrandLogoModalBodyJSX = (
        <div className='p-5'>
            <label
                htmlFor="brandLogo"
                className="flex flex-col bg-[#1e7992] items-center justify-center w-72 h-44 mx-auto border-2 border-gray-400 border-dashed rounded-lg cursor-pointer"
            >
                {addBrandLogoFile === null
                    ?
                    <div className='flex flex-col items-center justify-center'>
                        <ImageUp className='h-6 w-6 text-gray-400' />
                        <span className="mt-2 font-normal text-gray-400">Add Brand Logo</span>
                    </div>
                    :
                    <img
                        src={URL.createObjectURL(addBrandLogoFile)}
                        alt=""
                        className='object-fill max-h-44 max-w-72 rounded-lg hover:opacity-70 transition duration-300 ease-in-out'
                    />
                }
            </label>
            <input
                type="file"
                name="brandLogo"
                id="brandLogo"
                className="hidden"
                onChange={(e) => {
                    if (e.target.files.length > 0) {
                        setAddBrandLogoFile(e.target.files[0]);
                    }
                }}
            />

        </div>
    )

    const handleAddLogo = () => {
        if (addBrandLogoFile === null) {
            toast.error('Please select a file to upload');
            return;
        }

        const formData = new FormData();
        formData.append('logo', addBrandLogoFile);
        formData.append('token', user.token);

        BrandLogoApiService.addBrandLogo(formData)
            .then(res => {
                if (res.data.success === true) {
                    toast.success('Brand Logo added successfully');
                    getBrandLogos();
                    setAddBrandLogoFile(null);
                    setShowAddBrandLogo(false);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleDeleteLogo = () => {
        let body = {
            token: user.token,
            id: deleteBrandLogoId
        }
        BrandLogoApiService.deleteBrandLogo(body)
            .then(res => {
                if (res.data.success === true) {
                    toast.success('Brand Logo deleted successfully');
                    getBrandLogos();
                    setDeleteBrandLogoId(null);
                    setShowDeleteBrandLogo(false);
                }
            })
            .catch(err => {
                console.log(err)
                toast.error(err.response.data.message);
            })
    }

    return (
        <div className='p-5'>
            {/* Add */}
            <HeadlessUIModalComponent
                displayState={showAddBrandLogo}
                setDisplayState={setShowAddBrandLogo}
                headingChildren={'Add Brand Logo'}
                bodyChildren={addBrandLogoModalBodyJSX}
                footerChildren={
                    <div className='flex gap-2'>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-sm font-medium text-teal-100 hover:bg-teal-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                            onClick={handleAddLogo}
                        >
                            Add Logo
                        </button>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-teal-200 px-4 py-2 text-sm font-medium text-teal-900 hover:bg-teal-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                            onClick={() => { setShowAddBrandLogo(false) }}
                        >
                            Cancel
                        </button>
                    </div>
                }
            />

            {/* Display */}
            <HeadlessUIModalComponent
                displayState={showDisplayBrandLogo}
                setDisplayState={setDisplayBrandLogoId}
                headingChildren={'Brand Logo'}
                bodyChildren={
                    <div className='flex items-center justify-center bg-[#1e7992]'>
                        <img
                            src={LOGOS_BASE_URL + brandLogos.find(logo => logo.id === displayBrandLogoId)?.image}
                            alt=""
                            className='object-fill max-h-60 max-w-96 mx-auto my-5 rounded-lg'
                        />
                    </div>
                }
                footerChildren={
                    <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-teal-200 px-4 py-2 text-sm font-medium text-teal-900 hover:bg-teal-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                        onClick={() => { setShowDisplayBrandLogo(false) }}
                    >
                        Close
                    </button>
                }
            />

            {/* Delete */}
            <HeadlessUIModalComponent
                displayState={showDeleteBrandLogo}
                setDisplayState={setShowDeleteBrandLogo}
                headingChildren={'Delete Brand Logo'}
                bodyChildren={<p className='my-5'>Are you sure you want to delete this Brand Logo?</p>}
                footerChildren={
                    <div className='flex gap-2'>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-red-100 hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                            onClick={handleDeleteLogo}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-red-200 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2"
                            onClick={() => { setShowDeleteBrandLogo(false) }}
                        >
                            Cancel
                        </button>
                    </div>
                }
            />


            <div className='flex items-center justify-between' >
                <h1 className='text-2xl'>Brand Logos</h1>
                <button
                    onClick={() => setShowAddBrandLogo(true)}
                    className='bg-teal-200 flex items-center gap-1 font-medium text-[15px] py-2 px-4 rounded-lg hover:bg-teal-300 text-teal-900 focus:outline-none'
                >
                    <Plus className='h-5 w-5' /> Add New Brand Logo
                </button>
            </div>

            <div>
                {brandLogoListJSX}
            </div>
        </div>
    )
}

export default BrandLogos;